.level-card-wrapper {
  .level-title {
    text-align: center;
    font: normal normal 300 36px/44px Proxima Nova;
    margin-top: 50px;
    // display: flex;
    // flex-basis: 100%;
    // align-items: center;
    // color: $white;
    // margin: 15px 0px;
    // font-size: 16px;

    // &:before,
    // &:after {
    //   content: '';
    //   flex-grow: 1;
    //   background: $graphite;
    //   height: 1px;
    //   font-size: 0px;
    //   line-height: 0px;
    //   margin: 0px 8px;
    // }
  }

  .content {
    margin-bottom: 30px;

    .content-centered {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      cursor: pointer;
      margin-top: 20px;
      padding-top: 10px;

      .content-image {
        width: 30px;

        &.hidden {
          display: none;
        }
      }
    }

    .card-content-wrapper {
      width: 483px;
      height: 134px;
      margin-top: 20px;
    }
  }
}
