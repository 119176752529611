.ib-card-wrapper {
  height: 100%;
  min-height: 110px;

  .ib-card-title {
    text-align: center;
    color: $white;
    margin-top: 10px;
    font-size: 14px;
    min-height: 40px;
  }

  .ib-card-subtitle {
    text-align: center;
    color: $white;
    font-size: smaller;
    margin-bottom: 5px;
  }

  // &.ib-card__active {
  //   background-color: $tropical-green;
  // }
}
.ib-card {
  background: $theme-hue;
  .ib-image-wrapper {
    width: 134px;
    height: 134px;
    background: $black2;
    .ib-image {
      width: 75px;
      display: block;
    }
  }
  .content {
    padding-left: 30px;
    .ib-card-title {
      font: normal normal 600 20px/24px Proxima Nova;
    }
    .ib-card-subtitle {
      font: normal normal 300 20px/24px Proxima Nova;
      margin-top: 10px;
      color: $white;
    }
  }
}

@media (max-width: 600px) {
  .ib-card-wrapper .ib-card {
    .ib-card-title {
      font-size: 11px;
      min-height: 30px;
    }
    .ib-card-subtitle {
      margin-top: 5px;
    }
  }
}
