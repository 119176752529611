// @import '@/assets/css/components/select.scss';

#ib_level {
  .query {
    margin-top: 30px;
    font: normal normal 300 20px/24px Proxima Nova;
    p {
      text-align: center;
      margin-bottom: 10px;
    }
    .el-select {
      width: 500px;
      margin: 0 auto;
    }
  }
  .ib_level_content--upper {
    display: flex;
    justify-content: center;

    .ib-level-card {
      background-color: transparent;
      width: 140px;
      min-height: 140px;
      cursor: pointer;
      box-shadow: inset 0px 0px 5px 2px $tropical-green;
      color: $white;
      text-align: center;

      .ib-level-image-wrapper {
        display: flex;
        justify-content: center;

        .ib-image--upper {
          margin-top: 5px;
          width: 50px;
          display: block;
        }
      }

      .ib_level_content-title {
        margin: 10px 0 5px;
        font-size: 15px;
        word-wrap: break-word;
        padding: 0 3px;
        min-height: 40px;
      }
    }
  }
  .ib_level--alert {
    p {
      font-size: 16px;
      color: $white;
      text-align: center;
      margin: 30px 0;
    }
  }
}
